<p-menubar [model]="items" *ngIf="(currentUser$ | async) as user" [autoDisplay]="true">
    <ng-template pTemplate="start">
        <img src="assets/images/SL_LogoLive.png" alt="Image" height="42" class="ml-3 mr-4">
    </ng-template>
    <ng-template pTemplate="end">
        <div class="flex flex-row align-items-center ">
            <form action="https://www.paypal.com/donate" method="post" target="_top">
                <input type="hidden" name="hosted_button_id" value="5QU8L35WSEYNC" />
                <input type="image" src="https://www.paypalobjects.com/en_AU/i/btn/btn_donate_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!"
                    alt="Donate with PayPal button" />
                <img alt="" border="0" src="https://www.paypal.com/en_AU/i/scr/pixel.gif" width="1" height="1" />
            </form>
            <a class="text-500 cursor-pointer lg:py-2 lg:px-2 hover:border-blue-500 transition-colors transition-duration-150 lg:border-bottom-2 border-transparent" (click)="gotoProfile()" pTooltip="Edit Profile" tooltipPosition="bottom">
                <i class="fa-solid fa-user mr-2 text-lg"></i>
                <span class="">{{user.firstName}} {{user.surname}}</span>
            </a>
            <a pRipple pTooltip="Sign Out..." class="flex p-3 h-full lg:px-3 lg:py-2 align-items-center text-600 border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-blue-500 font-medium cursor-pointer transition-colors transition-duration-150">
                <i class="fa-solid fa-right-from-bracket text-base lg:text-2xl mr-2 lg:mr-0" (click)="logout()"
                    title="Logout"></i>
            </a>
        </div>
    </ng-template>
</p-menubar>

<!-- <div class="surface-overlay px-6 shadow-2 flex justify-content-between relative lg:static" style="min-height: 60px">
    <img src="assets/images/SL_LogoLive.png" alt="Image" height="40" class="mr-0 lg:mr-4 align-self-center">
    <a pRipple class="cursor-pointer block lg:hidden align-self-center text-700" pStyleClass="@next" enterClass="hidden" leaveToClass="hidden" [hideOnOutsideClick]="true" #hamburgers>
        <i class="pi pi-bars text-4xl"></i>
    </a>
    <div class="flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full surface-overlay left-0 top-100 z-1 shadow-2 lg:shadow-none" *ngIf="(currentUser$ | async) as user" (click)="somethingClicked()">
        <div class="flex">
            <ul class="list-none p-0 m-0 flex select-none flex-column lg:flex-row" *ngIf="!showAdmin">
                <li *ngFor="let item of menuItems">
                    <app-menu-item [item]="item"></app-menu-item>
                </li>
            </ul>
            <ul class="list-none p-0 m-0 flex select-none flex-column lg:flex-row" *ngIf="showAdmin">
                <li *ngFor="let item of adminMenuItems">
                    <app-menu-item [item]="item"></app-menu-item>
                </li>
            </ul>    
        </div>

        <ul class="list-none p-0 m-0 flex select-none flex-column lg:flex-row border-top-1 surface-border lg:border-top-none" >
            <li class="border-top-1 surface-border lg:border-top-none flex">
                <div class="lg:pl-3 pt-3 align-items-center text-500 hidden lg:block">
                    <span class="">{{user.firstName}} {{user.surname}}</span>
                </div>
                
                <a pRipple class="flex px-6 p-3 h-full lg:px-3 lg:py-2 align-items-center text-600 border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-blue-500 font-medium cursor-pointer transition-colors transition-duration-150">
                    <i class="fa-solid fa-right-from-bracket text-base lg:text-2xl mr-2 lg:mr-0" (click)="logout()" title="Logout"></i>
                    <div class="block lg:hidden">
                        <div class="text-900 font-medium">{{user.firstName}} {{user.surname}}</div>
                    </div>
                </a>
            </li>
        </ul>
    </div>
</div> -->