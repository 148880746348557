/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { acceptLeagueEntryRequest } from '../fn/league-entry-request/accept-league-entry-request';
import { AcceptLeagueEntryRequest$Params } from '../fn/league-entry-request/accept-league-entry-request';
import { addTournamentToLeague } from '../fn/league-entry-request/add-tournament-to-league';
import { AddTournamentToLeague$Params } from '../fn/league-entry-request/add-tournament-to-league';
import { addTournamentToLeague$Plain } from '../fn/league-entry-request/add-tournament-to-league-plain';
import { AddTournamentToLeague$Plain$Params } from '../fn/league-entry-request/add-tournament-to-league-plain';
import { rejectLeagueEntryRequest } from '../fn/league-entry-request/reject-league-entry-request';
import { RejectLeagueEntryRequest$Params } from '../fn/league-entry-request/reject-league-entry-request';

@Injectable({ providedIn: 'root' })
export class LeagueEntryRequestServiceAPI extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `acceptLeagueEntryRequest()` */
  static readonly AcceptLeagueEntryRequestPath = '/league/{leagueId}/entry/accept';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `acceptLeagueEntryRequest()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  acceptLeagueEntryRequest$Response(params: AcceptLeagueEntryRequest$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return acceptLeagueEntryRequest(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `acceptLeagueEntryRequest$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  acceptLeagueEntryRequest(params: AcceptLeagueEntryRequest$Params, context?: HttpContext): Observable<void> {
    return this.acceptLeagueEntryRequest$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `rejectLeagueEntryRequest()` */
  static readonly RejectLeagueEntryRequestPath = '/league/{leagueId}/entry/reject';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rejectLeagueEntryRequest()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  rejectLeagueEntryRequest$Response(params: RejectLeagueEntryRequest$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return rejectLeagueEntryRequest(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rejectLeagueEntryRequest$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  rejectLeagueEntryRequest(params: RejectLeagueEntryRequest$Params, context?: HttpContext): Observable<void> {
    return this.rejectLeagueEntryRequest$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `addTournamentToLeague()` */
  static readonly AddTournamentToLeaguePath = '/league/{leagueId}/entry/addTournament';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addTournamentToLeague$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  addTournamentToLeague$Plain$Response(params: AddTournamentToLeague$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return addTournamentToLeague$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addTournamentToLeague$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addTournamentToLeague$Plain(params: AddTournamentToLeague$Plain$Params, context?: HttpContext): Observable<string> {
    return this.addTournamentToLeague$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addTournamentToLeague()` instead.
   *
   * This method doesn't expect any request body.
   */
  addTournamentToLeague$Response(params: AddTournamentToLeague$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return addTournamentToLeague(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addTournamentToLeague$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addTournamentToLeague(params: AddTournamentToLeague$Params, context?: HttpContext): Observable<string> {
    return this.addTournamentToLeague$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}
