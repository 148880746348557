/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AccountResponseDto } from '../models/account-response-dto';
import { authenticate } from '../fn/accounts/authenticate';
import { Authenticate$Params } from '../fn/accounts/authenticate';
import { authenticate$Plain } from '../fn/accounts/authenticate-plain';
import { Authenticate$Plain$Params } from '../fn/accounts/authenticate-plain';
import { createAccount } from '../fn/accounts/create-account';
import { CreateAccount$Params } from '../fn/accounts/create-account';
import { createAccount$Plain } from '../fn/accounts/create-account-plain';
import { CreateAccount$Plain$Params } from '../fn/accounts/create-account-plain';
import { deleteAccount } from '../fn/accounts/delete-account';
import { DeleteAccount$Params } from '../fn/accounts/delete-account';
import { forgotPassword } from '../fn/accounts/forgot-password';
import { ForgotPassword$Params } from '../fn/accounts/forgot-password';
import { getAccountById } from '../fn/accounts/get-account-by-id';
import { GetAccountById$Params } from '../fn/accounts/get-account-by-id';
import { getAccountById$Plain } from '../fn/accounts/get-account-by-id-plain';
import { GetAccountById$Plain$Params } from '../fn/accounts/get-account-by-id-plain';
import { getAllAccounts } from '../fn/accounts/get-all-accounts';
import { GetAllAccounts$Params } from '../fn/accounts/get-all-accounts';
import { getAllAccounts$Plain } from '../fn/accounts/get-all-accounts-plain';
import { GetAllAccounts$Plain$Params } from '../fn/accounts/get-all-accounts-plain';
import { getAllPlayerInfo } from '../fn/accounts/get-all-player-info';
import { GetAllPlayerInfo$Params } from '../fn/accounts/get-all-player-info';
import { getAllPlayerInfo$Plain } from '../fn/accounts/get-all-player-info-plain';
import { GetAllPlayerInfo$Plain$Params } from '../fn/accounts/get-all-player-info-plain';
import { logout } from '../fn/accounts/logout';
import { Logout$Params } from '../fn/accounts/logout';
import { mergePlayers } from '../fn/accounts/merge-players';
import { MergePlayers$Params } from '../fn/accounts/merge-players';
import { refreshToken } from '../fn/accounts/refresh-token';
import { RefreshToken$Params } from '../fn/accounts/refresh-token';
import { refreshToken$Plain } from '../fn/accounts/refresh-token-plain';
import { RefreshToken$Plain$Params } from '../fn/accounts/refresh-token-plain';
import { registerAccount } from '../fn/accounts/register-account';
import { RegisterAccount$Params } from '../fn/accounts/register-account';
import { resetPassword } from '../fn/accounts/reset-password';
import { ResetPassword$Params } from '../fn/accounts/reset-password';
import { revokeToken } from '../fn/accounts/revoke-token';
import { RevokeToken$Params } from '../fn/accounts/revoke-token';
import { updateAccount } from '../fn/accounts/update-account';
import { UpdateAccount$Params } from '../fn/accounts/update-account';
import { updateAccount$Plain } from '../fn/accounts/update-account-plain';
import { UpdateAccount$Plain$Params } from '../fn/accounts/update-account-plain';
import { UserAccountDto } from '../models/user-account-dto';
import { UserPlayerInfoDto } from '../models/user-player-info-dto';
import { validateResetToken } from '../fn/accounts/validate-reset-token';
import { ValidateResetToken$Params } from '../fn/accounts/validate-reset-token';
import { verifyEmail } from '../fn/accounts/verify-email';
import { VerifyEmail$Params } from '../fn/accounts/verify-email';

@Injectable({ providedIn: 'root' })
export class AccountsServiceAPI extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `authenticate()` */
  static readonly AuthenticatePath = '/accounts/authenticate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticate$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  authenticate$Plain$Response(params?: Authenticate$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserAccountDto>> {
    return authenticate$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticate$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  authenticate$Plain(params?: Authenticate$Plain$Params, context?: HttpContext): Observable<UserAccountDto> {
    return this.authenticate$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserAccountDto>): UserAccountDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticate()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  authenticate$Response(params?: Authenticate$Params, context?: HttpContext): Observable<StrictHttpResponse<UserAccountDto>> {
    return authenticate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticate$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  authenticate(params?: Authenticate$Params, context?: HttpContext): Observable<UserAccountDto> {
    return this.authenticate$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserAccountDto>): UserAccountDto => r.body)
    );
  }

  /** Path part for operation `refreshToken()` */
  static readonly RefreshTokenPath = '/accounts/refresh-token';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `refreshToken$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  refreshToken$Plain$Response(params?: RefreshToken$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserAccountDto>> {
    return refreshToken$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `refreshToken$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  refreshToken$Plain(params?: RefreshToken$Plain$Params, context?: HttpContext): Observable<UserAccountDto> {
    return this.refreshToken$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserAccountDto>): UserAccountDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `refreshToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  refreshToken$Response(params?: RefreshToken$Params, context?: HttpContext): Observable<StrictHttpResponse<UserAccountDto>> {
    return refreshToken(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `refreshToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  refreshToken(params?: RefreshToken$Params, context?: HttpContext): Observable<UserAccountDto> {
    return this.refreshToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserAccountDto>): UserAccountDto => r.body)
    );
  }

  /** Path part for operation `logout()` */
  static readonly LogoutPath = '/accounts/logout';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `logout()` instead.
   *
   * This method doesn't expect any request body.
   */
  logout$Response(params?: Logout$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return logout(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `logout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  logout(params?: Logout$Params, context?: HttpContext): Observable<void> {
    return this.logout$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `revokeToken()` */
  static readonly RevokeTokenPath = '/accounts/revoke-token';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `revokeToken()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  revokeToken$Response(params?: RevokeToken$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return revokeToken(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `revokeToken$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  revokeToken(params?: RevokeToken$Params, context?: HttpContext): Observable<void> {
    return this.revokeToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `registerAccount()` */
  static readonly RegisterAccountPath = '/accounts/register';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerAccount()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  registerAccount$Response(params?: RegisterAccount$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return registerAccount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registerAccount$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  registerAccount(params?: RegisterAccount$Params, context?: HttpContext): Observable<void> {
    return this.registerAccount$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `verifyEmail()` */
  static readonly VerifyEmailPath = '/accounts/verify-email/{token}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `verifyEmail()` instead.
   *
   * This method doesn't expect any request body.
   */
  verifyEmail$Response(params: VerifyEmail$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return verifyEmail(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `verifyEmail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  verifyEmail(params: VerifyEmail$Params, context?: HttpContext): Observable<void> {
    return this.verifyEmail$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `forgotPassword()` */
  static readonly ForgotPasswordPath = '/accounts/forgot-password';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `forgotPassword()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  forgotPassword$Response(params?: ForgotPassword$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return forgotPassword(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `forgotPassword$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  forgotPassword(params?: ForgotPassword$Params, context?: HttpContext): Observable<void> {
    return this.forgotPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `validateResetToken()` */
  static readonly ValidateResetTokenPath = '/accounts/validate-reset-token';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateResetToken()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  validateResetToken$Response(params?: ValidateResetToken$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return validateResetToken(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `validateResetToken$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  validateResetToken(params?: ValidateResetToken$Params, context?: HttpContext): Observable<void> {
    return this.validateResetToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `resetPassword()` */
  static readonly ResetPasswordPath = '/accounts/reset-password';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resetPassword()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  resetPassword$Response(params?: ResetPassword$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return resetPassword(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resetPassword$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  resetPassword(params?: ResetPassword$Params, context?: HttpContext): Observable<void> {
    return this.resetPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getAllAccounts()` */
  static readonly GetAllAccountsPath = '/accounts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllAccounts$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAccounts$Plain$Response(params?: GetAllAccounts$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AccountResponseDto>>> {
    return getAllAccounts$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllAccounts$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAccounts$Plain(params?: GetAllAccounts$Plain$Params, context?: HttpContext): Observable<Array<AccountResponseDto>> {
    return this.getAllAccounts$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AccountResponseDto>>): Array<AccountResponseDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllAccounts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAccounts$Response(params?: GetAllAccounts$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AccountResponseDto>>> {
    return getAllAccounts(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllAccounts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAccounts(params?: GetAllAccounts$Params, context?: HttpContext): Observable<Array<AccountResponseDto>> {
    return this.getAllAccounts$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AccountResponseDto>>): Array<AccountResponseDto> => r.body)
    );
  }

  /** Path part for operation `createAccount()` */
  static readonly CreateAccountPath = '/accounts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAccount$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createAccount$Plain$Response(params?: CreateAccount$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountResponseDto>> {
    return createAccount$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createAccount$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createAccount$Plain(params?: CreateAccount$Plain$Params, context?: HttpContext): Observable<AccountResponseDto> {
    return this.createAccount$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountResponseDto>): AccountResponseDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAccount()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createAccount$Response(params?: CreateAccount$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountResponseDto>> {
    return createAccount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createAccount$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createAccount(params?: CreateAccount$Params, context?: HttpContext): Observable<AccountResponseDto> {
    return this.createAccount$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountResponseDto>): AccountResponseDto => r.body)
    );
  }

  /** Path part for operation `getAccountById()` */
  static readonly GetAccountByIdPath = '/accounts/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccountById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccountById$Plain$Response(params: GetAccountById$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountResponseDto>> {
    return getAccountById$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAccountById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccountById$Plain(params: GetAccountById$Plain$Params, context?: HttpContext): Observable<AccountResponseDto> {
    return this.getAccountById$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountResponseDto>): AccountResponseDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccountById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccountById$Response(params: GetAccountById$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountResponseDto>> {
    return getAccountById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAccountById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccountById(params: GetAccountById$Params, context?: HttpContext): Observable<AccountResponseDto> {
    return this.getAccountById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountResponseDto>): AccountResponseDto => r.body)
    );
  }

  /** Path part for operation `updateAccount()` */
  static readonly UpdateAccountPath = '/accounts/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAccount$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateAccount$Plain$Response(params: UpdateAccount$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountResponseDto>> {
    return updateAccount$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAccount$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateAccount$Plain(params: UpdateAccount$Plain$Params, context?: HttpContext): Observable<AccountResponseDto> {
    return this.updateAccount$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountResponseDto>): AccountResponseDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAccount()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateAccount$Response(params: UpdateAccount$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountResponseDto>> {
    return updateAccount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAccount$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateAccount(params: UpdateAccount$Params, context?: HttpContext): Observable<AccountResponseDto> {
    return this.updateAccount$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountResponseDto>): AccountResponseDto => r.body)
    );
  }

  /** Path part for operation `deleteAccount()` */
  static readonly DeleteAccountPath = '/accounts/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAccount$Response(params: DeleteAccount$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteAccount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAccount(params: DeleteAccount$Params, context?: HttpContext): Observable<void> {
    return this.deleteAccount$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getAllPlayerInfo()` */
  static readonly GetAllPlayerInfoPath = '/accounts/player-info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllPlayerInfo$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPlayerInfo$Plain$Response(params?: GetAllPlayerInfo$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserPlayerInfoDto>>> {
    return getAllPlayerInfo$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllPlayerInfo$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPlayerInfo$Plain(params?: GetAllPlayerInfo$Plain$Params, context?: HttpContext): Observable<Array<UserPlayerInfoDto>> {
    return this.getAllPlayerInfo$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserPlayerInfoDto>>): Array<UserPlayerInfoDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllPlayerInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPlayerInfo$Response(params?: GetAllPlayerInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserPlayerInfoDto>>> {
    return getAllPlayerInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllPlayerInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPlayerInfo(params?: GetAllPlayerInfo$Params, context?: HttpContext): Observable<Array<UserPlayerInfoDto>> {
    return this.getAllPlayerInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserPlayerInfoDto>>): Array<UserPlayerInfoDto> => r.body)
    );
  }

  /** Path part for operation `mergePlayers()` */
  static readonly MergePlayersPath = '/accounts/merge-players';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mergePlayers()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  mergePlayers$Response(params?: MergePlayers$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return mergePlayers(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mergePlayers$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  mergePlayers(params?: MergePlayers$Params, context?: HttpContext): Observable<void> {
    return this.mergePlayers$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
