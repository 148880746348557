/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PlayerRankingDto } from '../../models/player-ranking-dto';

export interface GetLeagueLadder$Params {
  leagueId: string;
  seasonId?: string;
  limit?: number;
}

export function getLeagueLadder(http: HttpClient, rootUrl: string, params: GetLeagueLadder$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PlayerRankingDto>>> {
  const rb = new RequestBuilder(rootUrl, getLeagueLadder.PATH, 'get');
  if (params) {
    rb.path('leagueId', params.leagueId, {});
    rb.query('seasonId', params.seasonId, {});
    rb.query('limit', params.limit, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<PlayerRankingDto>>;
    })
  );
}

getLeagueLadder.PATH = '/league/{leagueId}/ladder';
